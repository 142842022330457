import { FriendLinkType } from "@src/app/store/workerFriends/model";
import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";
import { Shift } from "@src/lib/interface/src";

import { IColleagueInfo } from "./workWithFriends.types";

export interface IBookedBy {
  userId: string;
  qualification: string;
  name: string;
  hasProfilePic: boolean;
}

export enum ShiftContext {
  CALENDAR = "calendar",
  FACILITY = "facility",
  MY_SHIFTS = "my-shifts",
}

export interface IShiftSlot {
  workplaceUserId: string;
  start: string;
  end: string;
  stats: Record<
    string,
    {
      assignedShifts: number;
      totalShifts: number;
    }
  >;
  bookedBy: Array<IBookedBy>;
}

export interface WorkWithFriendsProps {
  shiftSlot: IShiftSlot;
  shift: Shift;
  context: ShiftContext;
}

export interface BookWithFriendsProps {
  shiftSlot: IShiftSlot;
  shift: Shift;
  context: ShiftContext;
  isBookedShift: boolean;
}

export interface BookWithFriendsModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleShareLinkOnClick: () => void;
  isBookedShift: boolean;
}

export interface ColleagueProfilePictureProps {
  colleague: IColleagueInfo;
}

export interface ColleaguePictureListProps {
  colleagues: IColleagueInfo[];
}

export type ViewAddFriendModalContext =
  | "DEEP_LINK"
  | "COLLEAGUES_LIST_OPEN_SHIFT"
  | "COLLEAGUES_LIST_POST_SHIFT_END"
  | "COLLEAGUES_LIST_UPCOMING_SHIFT";

export interface ColleagueInfoProps {
  colleagueInfo: IColleagueInfo;
  context: ViewAddFriendModalContext;
}

export interface UploadProfilePictureModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleOnContinueClick: () => void;
}

export interface FriendLinkAlertProps {
  isOpen: boolean;
  closeModal: () => void;
  handleOnContinueClick?: () => void;
  workerId?: string;
  header: string;
  message: string;
  hasProfilePic?: boolean;
  type: FriendLinkType;
  name?: string;
  qualification?: string;
  imageUrl?: string;
  context: ViewAddFriendModalContext;
}

export interface ColleaguesSummaryProps {
  colleagueList: IColleagueInfo[];
  shiftSlotStats: IShiftSlot["stats"];
  context?: ShiftContext;
  shiftQualification?: string | undefined;
}

export interface WorkerFriendProfilePictureProps {
  name: string;
  workerId: string;
  hasProfilePic: boolean;
  imageUrl?: string;
}

export interface IAddFriendModalProps {
  onDismiss: () => void;
}

export interface IFriendLinkAlertProps {
  type: FriendLinkType;
  header: string;
  message: string;
  hasProfilePic?: boolean;
  workerId?: string;
  bookWithMeId: string;
  name?: string;
  qualification?: string;
}

export interface IFriendsBookedSlots {
  facilityId: string;
  start: Date;
  end: Date;
  name: ShiftPeriod;
  friends: string[];
}

export interface IOpenShiftSlot {
  facilityId: string;
  start: Date;
  end: Date;
}
