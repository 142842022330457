import { IonAvatar, IonButton, IonContent, IonModal, IonText } from "@ionic/react";
import { FC } from "react";

import { UploadProfilePictureModalProps } from "./interface";

import "./style.scss";

export const UploadProfilePictureModal: FC<UploadProfilePictureModalProps> = ({
  isOpen,
  closeModal,
  handleOnContinueClick,
}) => {
  return (
    <IonModal isOpen={isOpen} onWillDismiss={closeModal} cssClass="profile-picture-modal">
      <IonContent className="profile-picture-modal-container">
        <div className="profile-picture-modal-container-content">
          <IonText className="modal-title">
            <h2>See who you will be working with</h2>
          </IonText>
          <IonAvatar
            className="colleague-profile-pic-avatar profile-picture-modal-avatar profile-pic-avatar-border"
            data-testid="colleague-profile-pic-avatar"
          >
            <img
              data-testid="colleague-image"
              src="assets/images/colleagues/dummy-1.jpg"
              alt="profile"
            />
          </IonAvatar>
          <IonText className="modal-subtitle">
            <p>
              You need to upload a profile picture before you can see who you will be working with.
            </p>
            <em>{"You can disable this at any time under My Account > Profile."}</em>
          </IonText>
        </div>
        <div className="profile-picture-modal-container-action">
          <IonButton
            expand="full"
            shape="round"
            fill="solid"
            color="primary"
            onClick={handleOnContinueClick}
          >
            Continue
          </IonButton>
          <IonButton
            expand="full"
            shape="round"
            className="profile-picture-modal-cancel-action"
            fill="outline"
            onClick={closeModal}
          >
            Cancel
          </IonButton>
        </div>
      </IonContent>
    </IonModal>
  );
};
