import "./style.scss";

import { RefresherEventDetail, SelectChangeEventDetail } from "@ionic/core";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { TabRouterPath } from "@src/app/routing/constant/tabRoute";
import { useAppSelector } from "@src/app/store";
import {
  fetchAndSetWorkerFriendRequests,
  fetchAndSetWorkerFriends,
} from "@src/app/store/workerFriends/actions";
import { WorkerFriendRequestStatus } from "@src/app/store/workerFriends/model";
import { ActionType } from "@src/app/store/workerFriends/model";
import { useToast } from "@src/appV2/lib";
import { deprecatedDoNotUseLogError, logEvent } from "@src/appV2/lib/analytics";
import { useLogEffect } from "@src/appV2/lib/analytics/useLogEffect";
import { USER_EVENTS } from "@src/constants/userEvents";
import { personAdd } from "ionicons/icons";
import { FC, ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { AddFriendModal } from "./AddFriendModal";
import { sendWorkerFriendRequest } from "./api";
import { FriendLinkAlert } from "./friendLinkAlert";
import { WorkWithFriendsInfoBox } from "./work-with-friends-info-box";
import { WorkerFriendList } from "./WorkerFriendList";
import { WorkerFriendRequestList } from "./WorkerFriendRequestList";

export enum tab {
  friends = "friends",
  sent = "friend-requests-sent",
  invites = "friend-requests-received",
}

const getSelectOptionLabelFromStatus = (status: tab): string => {
  switch (status) {
    case tab.friends:
      return "Friends";
    case tab.invites:
      return "Invitations";
    case tab.sent:
      return "Pending Friend Requests";
  }
};

export const WorkerFriendsList: FC = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const dispatch = useDispatch();

  const history = useHistory();
  const { friendsListInitialTab: initialTab } =
    (history?.location?.state as { friendsListInitialTab: tab }) || {};

  const [status, setStatus] = useState<tab>(initialTab ?? tab.friends);
  const [isAddFriendModalOpen, setIsAddFriendModalOpen] = useState<boolean>(false);

  const workerFriends = useAppSelector((state) => {
    return state.workerFriendsStore.workerFriends;
  });

  const workerFriendRequests = useAppSelector((state) => {
    const { workerFriendsStore } = state;
    const defaultValue = {
      loading: false,
      data: null,
    };
    if (status === tab.invites) {
      return (
        workerFriendsStore.workerFriendRequests[WorkerFriendRequestStatus.RECEIVED] ?? defaultValue
      );
    }
    if (status === tab.sent) {
      return (
        workerFriendsStore.workerFriendRequests[WorkerFriendRequestStatus.SENT] ?? defaultValue
      );
    }
    return defaultValue;
  });
  const isFriendsTab = status === tab.friends;
  const workerDataIsLoading = isFriendsTab ? workerFriends.loading : workerFriendRequests.loading;

  const friendLinkAlertProps = useAppSelector(
    (state) => state.workerFriendsStore.friendLinkAlertProps
  );

  useEffect(() => {
    if (status === tab.friends) {
      fetchAndSetWorkerFriends(dispatch);
    } else {
      const friendRequestStatus =
        status === tab.invites
          ? WorkerFriendRequestStatus.RECEIVED
          : WorkerFriendRequestStatus.SENT;
      fetchAndSetWorkerFriendRequests(dispatch, friendRequestStatus);
    }
  }, [dispatch, status]);

  const onStatusChange = (event: CustomEvent<SelectChangeEventDetail>) => {
    const { value } = event.detail;
    setStatus(value);
  };

  const onCloseAddFriendModal = () => setIsAddFriendModalOpen(false);
  const onClickAddFriend = () => {
    logEvent(USER_EVENTS.VIEW_ADD_FRIEND);
    setIsAddFriendModalOpen(true);
  };

  useLogEffect(USER_EVENTS.FRIENDS_LIST_VIEWED);

  const onClickSendFriendRequest = async () => {
    try {
      if (friendLinkAlertProps?.bookWithMeId) {
        await sendWorkerFriendRequest(friendLinkAlertProps?.bookWithMeId);
        logEvent(USER_EVENTS.FRIEND_REQUEST_SENT, {
          requesteeId: friendLinkAlertProps.workerId,
        });
        fetchAndSetWorkerFriendRequests(dispatch, WorkerFriendRequestStatus.SENT);
        showSuccessToast("Friend request sent!");
      }
    } catch (error) {
      deprecatedDoNotUseLogError({
        message: `Error while sending friend request. ${JSON.stringify(error?.stack || error)}`,
      });
      showErrorToast("There was an error while sending the friend request, please try again!");
    }
    dispatch({
      type: ActionType.SHOW_FRIEND_LINK_ALERT,
      data: null,
    });
  };

  const closeConfirmModal = () => {
    dispatch({
      type: ActionType.SHOW_FRIEND_LINK_ALERT,
      data: null,
    });
  };

  const onRefresh = (event: CustomEvent<RefresherEventDetail>) => {
    if (status === tab.friends) {
      fetchAndSetWorkerFriends(dispatch);
    } else {
      const friendRequestStatus =
        status === tab.invites
          ? WorkerFriendRequestStatus.RECEIVED
          : WorkerFriendRequestStatus.SENT;
      fetchAndSetWorkerFriendRequests(dispatch, friendRequestStatus);
    }
    event.detail.complete();
  };

  // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
  const noFriendsContent = (
    <div className="friends-list-no-friends-label-container">
      <p className="friends-list-no-friends-label">
        No {getSelectOptionLabelFromStatus(status).toLowerCase()} to show
      </p>
    </div>
  );

  // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
  let workerFriendListContent: ReactNode;
  if (workerDataIsLoading) {
    workerFriendListContent = (
      <div className="friends-list-no-friends-label-container">
        <IonSpinner name="lines" slot="start" />
      </div>
    );
  } else {
    if (isFriendsTab) {
      if (!workerFriends.data || workerFriends.data.length === 0) {
        // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
        workerFriendListContent = noFriendsContent;
      } else {
        // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
        workerFriendListContent = <WorkerFriendList workerFriendsList={workerFriends.data} />;
      }
    } else {
      if (!workerFriendRequests.data || workerFriendRequests.data.length === 0) {
        // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
        workerFriendListContent = noFriendsContent;
      } else {
        // FIXME - extract this logic out into a separate component in a new file as it is too complex to be placed inline.
        workerFriendListContent = (
          <WorkerFriendRequestList workerFriendRequestsList={workerFriendRequests.data} />
        );
      }
    }
  }

  return (
    <IonPage>
      <IonHeader no-border>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref={TabRouterPath.ACCOUNT} mode="ios" />
          </IonButtons>
          <IonTitle>Work with Friends</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>
        <WorkWithFriendsInfoBox />
        <div className="friends-list-add-friend-button-container">
          <IonButton size="small" fill="solid" onClick={onClickAddFriend}>
            <IonIcon icon={personAdd} mode="ios" class="friends-list-add-friend-button-icon" />
            Add Friend
          </IonButton>
        </div>
        <IonSegment
          onIonChange={onStatusChange}
          value={status}
          className="friends-list-status-select"
          data-testid="friends-list-tab-select"
          mode="ios"
        >
          {Object.keys(tab).map((tabItem) => (
            <IonSegmentButton
              key={`wwf_select_${tabItem}`}
              value={tab[tabItem]}
              data-testid={`friends-list-tab-option-${tab[tabItem]}`}
            >
              <IonLabel>{getSelectOptionLabelFromStatus(tab[tabItem])}</IonLabel>
            </IonSegmentButton>
          ))}
        </IonSegment>
        {workerFriendListContent}
      </IonContent>
      {isAddFriendModalOpen && <AddFriendModal onDismiss={onCloseAddFriendModal} />}
      {friendLinkAlertProps && (
        <FriendLinkAlert
          message={friendLinkAlertProps.message}
          header={friendLinkAlertProps.header}
          isOpen={!!friendLinkAlertProps.type}
          type={friendLinkAlertProps.type}
          hasProfilePic={friendLinkAlertProps?.hasProfilePic}
          workerId={friendLinkAlertProps?.workerId}
          name={friendLinkAlertProps?.name}
          qualification={friendLinkAlertProps?.qualification}
          handleOnContinueClick={onClickSendFriendRequest}
          closeModal={closeConfirmModal}
          context="DEEP_LINK"
        />
      )}
    </IonPage>
  );
};
