import { ShiftPeriod } from "@src/appV2/Shifts/Shift/types";

export interface IColleagueInfo {
  colleagueUserId: string;
  qualification: string;
  colleagueName: string;
  shiftStart?: string;
  facilityName?: string;
  shiftName?: ShiftPeriod;
  hasProfilePic: boolean;
  isFriend?: boolean;
  isFriendRequestSent?: boolean;
}

export enum FriendRequestState {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  REQUESTED = "REQUESTED",
}
